
// @ is an alias to /src test env
import { reactive, ref } from "vue";
import * as moment from "moment";
import Swal from "sweetalert2";
import Auth from "../../services/routes";
import Header from "../../components/Header/index.vue";
import lodash from "lodash";
import Multiselect from "@vueform/multiselect";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Vue3Html2pdf from "vue3-html2pdf";
import html2pdf from "html2pdf.js"
export default {
  name: "Home",
  components: { Header, Multiselect, QuillEditor, Vue3Html2pdf },
  data() {
    return {
 
      MusculosSelecionados: [],
      value: [],
      descricao: "",
      categoria: "",
      categorias: "",
      Musculo: "",
      Musculos: "",
      Equipamento: "",
      Equipamentos: "",
      render: false,
      fileImage: null,
      showModal: false,
      fileName: false,
      activetab: 1,
      referencia: "",
      nome: null,
      imagem_musculo: null,
      load: false,
      tableData: [],
      API: null,
      expandRowKeys: [],
      pages: 1,
      tipo: 1,
      name_video: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    queriedData() {
      let result = lodash.orderBy(this.tableData, ["ID"], ["desc"]);
      /*
                                      let filtered = lodash.filter(result, item => {
                                        if(this.filter.filterDefault === "ALL") {
                                          return item.DES_FINANCE_CATEGORY.indexOf('') >=0;
                                        }
                                        else {
                                          return item.DES_FINANCE_CATEGORY.indexOf(this.filter.filterDefault) >=0;
                                        }  
                                      });

                                      this.tableDataFiltered = filtered
                                      */
      return result.slice(this.from, this.to);
    },
    pagedData() {
      if (!this.tableDataFiltered)
        return this.tableData.slice(this.from, this.to);
      else return this.tableDataFiltered.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (!this.tableDataFiltered)
        this.pagination.total = this.tableData.length;
      else this.pagination.total = this.tableDataFiltered.length;

      if (!this.tableDataFiltered) return this.tableData.length;
      else return this.tableDataFiltered.length;
    },
  },
  methods: {
 
    getItens() {
      this.load = true;
      Auth.getTreinos()
        .then((r) => {
          this.tableData = r.data.reverse();
          let i = 0;
          for (; i <= this.tableData.length - 1; i++) {
            this.tableData[i].idParaOrganizar = i;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    getCategorias() {
      this.load = true;
      Auth.getCategoria()
        .then((r) => {
          this.categorias = r.data.categorias.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    getExercicios() {
      this.load = true;
      Auth.getExercicios()
        .then((r) => {
          this.Musculos = r.data.reverse();
          let i = 0;
          for (; i <= this.Musculos.length - 1; i++) {
            this.Musculos[i].value = this.Musculos[i].nome;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    goTO(url) {
      this.$router.push(url);
    },
    getEquipamentos() {
      this.load = true;
      Auth.getEquipamento()
        .then((r) => {
          this.Equipamentos = r.data.equipamento.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    getMusculos() {
      this.load = true;
      Auth.getMusculo()
        .then((r) => {
          this.Musculo = r.data.musculos.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(row, index) {
      this.load = true;
      let data = {
        id: row.id,
        tabela: index,
      };
      Auth.deleteItem(data)
        .then((r) => {
          this.$notify({
            message: "Deletado com Sucesso!",
            title: "Sucesso",
            type: "success",
          });
        })
        .finally(() => {
          this.load = false;
          this.getItens();
          this.getCategorias();
          this.getExercicios();
          this.getEquipamentos();
          this.getMusculos();
        });
    },
  },
  mounted() {},
  created() {
    this.getItens();
    this.getCategorias();
    this.getExercicios();
    this.getEquipamentos();
    this.getMusculos();
    Auth.defaultW().then((r) => {
      this.API = r;
    });
  },
  setup() {
    const state = reactive({
      testes: "dCasa",
      numero: ref(0),
    });

    return {
      state,
    };
  },
};
